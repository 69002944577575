// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-template-js": () => import("./../../src/templates/AboutPageTemplate.js" /* webpackChunkName: "component---src-templates-about-page-template-js" */),
  "component---src-templates-discover-page-template-js": () => import("./../../src/templates/DiscoverPageTemplate.js" /* webpackChunkName: "component---src-templates-discover-page-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../src/templates/EventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-explore-page-template-js": () => import("./../../src/templates/ExplorePageTemplate.js" /* webpackChunkName: "component---src-templates-explore-page-template-js" */),
  "component---src-templates-in-practice-page-1-template-js": () => import("./../../src/templates/InPracticePage1Template.js" /* webpackChunkName: "component---src-templates-in-practice-page-1-template-js" */),
  "component---src-templates-in-practice-page-2-template-js": () => import("./../../src/templates/InPracticePage2Template.js" /* webpackChunkName: "component---src-templates-in-practice-page-2-template-js" */),
  "component---src-templates-in-practice-page-3-template-js": () => import("./../../src/templates/InPracticePage3Template.js" /* webpackChunkName: "component---src-templates-in-practice-page-3-template-js" */),
  "component---src-templates-in-practice-page-4-template-js": () => import("./../../src/templates/InPracticePage4Template.js" /* webpackChunkName: "component---src-templates-in-practice-page-4-template-js" */),
  "component---src-templates-index-page-template-js": () => import("./../../src/templates/IndexPageTemplate.js" /* webpackChunkName: "component---src-templates-index-page-template-js" */),
  "component---src-templates-participate-page-template-js": () => import("./../../src/templates/ParticipatePageTemplate.js" /* webpackChunkName: "component---src-templates-participate-page-template-js" */),
  "component---src-templates-resource-template-js": () => import("./../../src/templates/ResourceTemplate.js" /* webpackChunkName: "component---src-templates-resource-template-js" */)
}

